[data-sparta-container] {
  .spa-ui {
    &-task {
      background: $task-layer-background-color;
      border-bottom: 2px solid $task-layer-border-color;
      border-left: 1px solid $task-layer-border-color;
      border-right: 2px solid $task-layer-border-color;
      border-top: 1px solid $task-layer-border-color;
      line-height: 1;
      margin-right: 10px;
      min-height: 125px;
      position: absolute;
      width: 417px;
      z-index: 1000;

      &.full-width {
        left: 0 !important;
        width: 100% !important;
      }

      .spa-content {
        background: none repeat scroll 0 0 $task-layer-content-background-color;
        color: $task-layer-content-text-color;
        font: 14px/18px $spa-font-family;
      }

      .spa-header {
        padding: 10px 20px;
        position: relative;
      }

      .spa-body {
        padding: 10px 5px 15px 25px;

        p {
          margin-right: 15px;
        }

        h3 {
          border-bottom: 3px solid $task-layer-body-header-border-bottom-color;
        }
      }


      &.arrow:before,
      &.arrow:after {
        border: solid transparent;
        content: ' ';
        height: 0;
        pointer-events: none;
        position: absolute;
        width: 0;
      }

      &.arrow:before {
        border-color: rgba(194, 225, 245, 0);
      }

      &.arrow:after {
        border-color: rgba(136, 183, 213, 0);
      }


      &.arrow--bottom:before,
      &.arrow--bottom:after {
        left: 50%;
        top: 100%;
      }

      &.arrow--bottom:before {
        border-top-color: $task-layer-arrow-before-border-color;
        border-width: 29px;
        margin-left: -29px;
      }

      &.arrow--bottom:after {
        border-top-color: $task-layer-arrow-after-border-color;
        border-width: 25px;
        margin-left: -25px;
      }


      &.arrow--top:before,
      &.arrow--top:after {
        bottom: 100%;
        left: 50%;
      }

      &.arrow--top:before {
        border-bottom-color: $task-layer-arrow-before-border-color;
        border-width: 27px;
        margin-left: -27px;
      }

      &.arrow--top:after {
        border-bottom-color: $task-layer-arrow-after-border-color;
        border-width: 25px;
        margin-left: -25px;
      }


      &.arrow--left:before,
      &.arrow--left:after {
        right: 100%;
        top: 50%;
      }

      &.arrow--left:before {
        border-right-color: $task-layer-arrow-before-border-color;
        border-width: 26px;
        margin-top: -26px;
      }

      &.arrow--left:after {
        border-right-color: $task-layer-arrow-after-border-color;
        border-width: 25px;
        margin-top: -25px;
      }


      &.arrow--right:before,
      &.arrow--right:after {
        left: 100%;
        top: 50%;
      }

      &.arrow--right:before {
        border-left-color: $task-layer-arrow-before-border-color;
        border-width: 29px;
        margin-top: -29px;
      }

      &.arrow--right:after {
        border-left-color: $task-layer-arrow-after-border-color;
        border-width: 27px;
        margin-left: -1px;
        margin-top: -27px;
      }
    }
  }
}