




[data-sparta-container] {
  .spa-ui {
    &-modal {

      @include spa-card();

      -webkit-backface-visibility: hidden;
      background-color: transparent;
      bottom: auto;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: fixed;
      padding: 0;
      right: auto;
      top: 0;
      width: 100%;

      .spa-card__head {
        + .spa-card__body {
          padding-top: $spacing-16;
        }
      }


      .spa-layer-content {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        position: fixed;
        width: 100%;
        z-index: $nav-layer;


        &:before {
          content: "";
          display: block;
          @include screen-overlay(fixed);
        }


        &.spa-ui-position-top {
          align-items: flex-start;
        }

        &.spa-ui-position-right {
          justify-content: flex-end;
        }

        &.spa-ui-position-bottom {
          align-items: flex-end;
        }

        &.spa-ui-position-left {
          justify-content: flex-start;
        }
      }


      .spa-dialog {

        background: none repeat scroll 0 0 $modal-layer-content-color;
        border-radius: $modal-layer-bdr-radius;
        display: flex;
        margin: 20px;
        max-width: $large-max-width;
        min-width: $small-min-width;
        outline: 0;
        position: relative;
        top: 0;
        transition: $spa-standard-transition-property $spa-standard-transition-time $spa-standard-transition-easing;


        > .spa-card {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          height: 100%;
          max-height: 100vh;
          overflow: auto;
          position: relative;
          width: 100%;

          &--overlay {
            @include shadow-overlay();
          }
        }

        .spa-card__body {
          position: relative;
          -webkit-overflow-scrolling: touch;
          overflow: auto;
          max-height: $modal-layer-max-height;
        }

      }
      .spa-card__body {
        outline: none;
        overflow-y: auto;
        position: relative;
        margin-right: 2px;

        &,
        & p {
          color: $color-black;
          font: $spa-font;
        }


        .content {
          max-height: 100% !important;
          overflow: hidden;
          margin-right: 4px;
        }
      }
    }
  }


  .spa-ua-system-ios {
    .spa-ui-modal {
      .spa-dialog {
        position: fixed;
        top: 100px;
        -webkit-transform: translate(-50%, 0);
      }
    }
  }

  .spa-ua-system-iphone {
    .spa-ui-modal {
      .spa-dialog {
        top: 5px;
        width: 100% !important;
      }
    }
  }
}
