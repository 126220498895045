






[data-sparta-container] {
  .spa-ui-layer-info-link {
    border-bottom: 1px dotted $info-link-border;

    &:focus,
    &:hover {
      border-bottom: 1px solid $info-link-focus-border;
      text-decoration: none;
    }
  }

  .spa-ui-layer {
    .spa-content-alpha-layer {
      display: none;
      position: absolute;
      bottom: 16px;



      left: 10px;
      right: 10px;
      height: 40px;
      pointer-events: none;

      background: -moz-linear-gradient(bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%); 
      background: -webkit-linear-gradient(bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%); 
      background: linear-gradient(to top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%); 
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); 
    }

    [data-modal-footer] {
      + .spa-content-alpha-layer {
        bottom: 0;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: .5rem;
    }

    &-close {
      position: absolute;
      top: 12px;
      right: 15px;
      text-decoration: none;
      overflow: hidden;

      .spa-close-x {
        position: relative;
        color: $bank-brown-quinary;
        font-size: 40px;
        transition: $spa-standard-transition;
      }

      &:focus, &:hover {
        text-decoration: none !important;

        .spa-close-x {
          color: $bank-brown-tertiary;
          transition: $spa-standard-transition;
        }
      }
    }


    .spa-card__head,
    .spa-card__body,
    .spa-card__foot {
      position: relative;
    }


    .spa-card__head {
      margin-bottom: 0;
      &.spa-card__head-box-shadow {
        z-index: 1;
        > .spa-card__head-wrapper {
          @include shadow-bottom;
        }
      }
    }


    .spa-ui-info--no-title {
      .spa-card__head {
        margin-bottom: 0;
        &.spa-card__head-box-shadow {
          > .spa-card__head-wrapper {
            box-shadow: none;
          }
        }
      }
    }





    .spa-card,
    .spa-card__body {
      &.spa-card__body-box-shadow {
        &:after {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 16px;
          height: 16px;
          width: calc(100% - (16px * 2));
          box-shadow: 0 -12px 10px 12px rgba($color-brand-tertiary, .85);
        }
      }
    }
    .spa-card__body {
      &.spa-card__body-box-shadow {
        &:after {
          bottom: -16px;
          width: 100%;
          left: 0;
        }
      }
    }


    .spa-card__foot {
      &.spa-card__foot-box-shadow {
        z-index: 1;
        @include shadow-top;
      }
      &.spa-card__foot-box-shadow--empty {
        display: none;
      }
    }

        &.spa-ui-info .spa-card--medium .spa-card__foot {
      padding-top: $spacing-16 !important;
      margin-top: 0 !important;
    }

    &.spa-ui-drawer .spa-card--medium .spa-card__foot,
    &.spa-ui-modal .spa-card--medium .spa-card__foot {
      padding-top: $spacing-16;
      margin-top: 0;
    }

    .spa-body {
      color: $bank-dark-gray;
      font: 14px / 18px $spa-font-family;

      h3 {
        font-size: 18px;
        line-height: 25px;
        color: $bank-brown-tertiary;
        padding-bottom: 5px;
        margin-top: 0;
        line-height: 28px;
      }
    }

    .content {
      overflow-y: auto;
      outline: none;
      position: relative;
      border: 1px solid transparent;
      border-radius: 2px;

      &:focus {
        border-color: $color-royal-one;
      }

      .spa-btn {
        overflow: hidden;

        &.close-interstitial,
        &.close-spacing {
          margin-right: 15px;
        }

        &-footer {
          display: flex;
          justify-content: flex-end;
          @media #{$small-only} {
            flex-direction: column;
            .spa-btn {
                margin: $spacing-8 0;
                &:first-child {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
            }
          }

          .spa-ui-modal-btn--cancel {
            margin-right: 0;
          }

          &.right {
            justify-content: flex-end;
          }

                    &.center {
            justify-content: center;
          }

        }
      }
    }

    .spa-loading {
      &:focus {
        border: 1px solid $color-gray;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }


    .spa-card__foot--sticky {
      padding: 0 $spacing-16 $spacing-20 $spacing-16;

      > hr {
        padding: 8px 0;
        margin-left: -16px; 
        margin-right: -16px; 
        width: calc(100% + 32px);
        &:first-of-type {
          margin-top: -$spacing-16; 
          padding-top: 0;
        }
      }
    }
    .spa-btn-group-flex {
      display: flex;
      flex-wrap: wrap;

      &--right {
        justify-content: flex-end;
      }
      &--left {
        justify-content: flex-start;
      }
      &--center {
        justify-content: center;
      }
      &--justify {
        justify-content: space-between;
      }
    }

        .spa-card__foot-padding {
      padding-left: $spacing-16;
    }


    .ada-visible-focus {
      margin-top: $spacing-12;
      &:focus {
        position: relative;
        clip: auto !important;
        clip-path: none !important;
        transition: $spa-standard-transition;
        opacity: 1;
        height: auto;
        width: auto;
      }
    }

    .spa-dialog > .spa-card,
    > .spa-card {
      opacity: 0.9;
      transition: $spa-standard-transition;
    }

    &.spa-ui-modal-open,
    &.spa-ui-tooltip-open {
      .spa-dialog > .spa-card,
      > .spa-card {
        opacity: 1;
      }
    }
  }
}

body {
  &.spa-ui-freeze-scroll {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;

    @media #{$small-only} {
      .spa-layout-container {
        overflow: auto;
      }
    }

    .spa-layout-container {
      height: 100%;


      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        overflow: auto;
      }
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2)  {
        overflow: auto;
      }
    }
  }

  &.spa-ui-freeze-scroll.spa-ui-no-js-scroll {
    overflow: hidden;
    position: static;
  }
}
