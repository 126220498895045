




[data-sparta-container] {
  .spa-ui-drawer {
    @include spa-card();

    background: none;
    border-radius: 0;

    .spa-layer-content {
      @include screen-overlay(fixed);
    }

    .spa-dialog {
      position: fixed;

      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      margin: 0;
      transition: transform 400ms;

      > .spa-card {
        display: flex;
        flex-flow: column nowrap;
        background: $ui-layer-background-color;
        height: 100%;
        opacity: 1;

        .spa-card__body {
          flex-grow: 1;
          overflow-y: auto;
        }
      }
    }


    .spa-card__body > div {
      height: 100%;

      .content {
        display: grid;

        > [data-modal-footer] {
          align-self: end;
        }
      }
    }






    @each $position, $gap, $translate, $sm-sizes, $md-sizes in (

    'right' 'left' 100% [9.28] [4 '-small', 6 '-medium', 10 '-large'],

    'left' 'right' -100% [9.28] [4 '-small', 6 '-medium', 10 '-large'],

    'bottom' 'top' '0, 100%' [6 '-small', 9 '-medium', 10.8 '-large'] [3 '-small', 6 '-medium', 10.8 '-large']
    ) {

      @each $columns, $size in $sm-sizes {
        &.spa-ui-drawer-#{$position}.spa-ui-drawer#{$size} .spa-dialog {
          #{$gap}: calc((12 - #{$columns}) / 12 * 100%);


          transform: translate(#{$translate});
        }
      }

      @media #{$medium-up} {
        @each $columns, $size in $md-sizes {
          &.spa-ui-drawer-#{$position}.spa-ui-drawer#{$size} {
            .spa-dialog {
              #{$gap}: calc((12 - #{$columns}) / 12 * 100%);
            }
          }
        }
      }
    }

    .spa-dialog,
    .spa-card {
      border-radius: 0;
    }
  }


  @each $position in ('bottom', 'right', 'left') {
    .spa-ui-drawer.spa-ui-drawer-#{$position}.spa-ui-drawer-open .spa-dialog {
      transform: none;
    }
  }
}
