





@keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDownModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUpModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media #{$medium-up} {
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 25%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutUpModal {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDownModal {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media #{$medium-up} {
  @keyframes fadeOutUp {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
  }

  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, 25%, 0);
    }
  }
}

[data-sparta-widget] {
  .spa-ui-info {
    min-width: 300px;
  }
}

[data-sparta-container] {
  .spa-ui {
    &-layer-tooltip-link {
      color: $info-layer-link-color;
      font-size: $type-size-16;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-underline-offset: 4px;
      text-decoration-thickness: 1px;


      &:hover, &:focus {
        color: $info-layer-link-color;
        text-decoration: none;
        border-bottom-color: transparent;
      }
    }

    &-info {

      @include shadow-default;
      animation-duration: 0.5s;
      background-color: $color-brand-tertiary;
      border-radius: $info-layer-bdr-radius;
      height: auto;
      margin-top: $spacing-8;
      max-width: calc(100% - 20px); 
      padding: 0;
      position: fixed;
      transition: top 0s ease-in-out;
      width: 100%;
      z-index: $layer-level-3;

      @media #{$medium-up} {
        max-width: $info-layer-max-width;
      }

      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-iteration-count: 1;

      &.full-width {
        left: 0 !important;
        width: 100% !important;
      }

      &.spa-ui-animate-fadeInDown {
        animation-name: fadeInDown;
      }

      &.spa-ui-animate-fadeOutUp {
        animation-name: fadeOutUp;
      }

      &.spa-ui-animate-fadeInUp {
        animation-name: fadeInUp;
      }

      &.spa-ui-animate-fadeOutDown {
        animation-name: fadeOutDown;
      }


      .spa-card--small,
      .spa-card--medium,
      .spa-card--large,
      .spa-card--xlarge {
        .spa-card__head {
          margin-bottom: 0 !important;
        }
      }


      .spa-ui-info--no-title {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        .spa-card__head {
          flex-basis: 44px;
          margin-left: 0 !important;
          order: 2;
        }

        .spa-card__body {
          flex-basis: calc(100% - 36px);
          order: 1;
        }

        .ada-visible-focus {
          order: 3;
        }
      }

      .spa-card__head {
        .spa-card__head-wrapper {
          min-height: 0px;
        }
      }

      .spa-card__title {
        &,
        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
          color: $color-black !important;
          font-family: $roboto-bold-family !important;
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }

      .spa-card__body {
        color: $color-black;
        -webkit-overflow-scrolling: auto;

        p {
          color: $color-black !important;
          font-size: 16px !important;
          line-height: 1.3 !important;
        }

        a {
          color: $color-royal-one !important;
          font-size: 16px !important;
          line-height: 1.3 !important;

          &:hover {
            color: $color-royal-one !important;
          }
        }

        .content {
          max-height: $info-layer-content-max-height;
          position: relative;

          > p {
            margin-bottom: $spacing-10;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &.spa-ui-info-modal {
        animation-duration: 0.7s;
        background: $info-layer-overlay-color;
        padding: 20px 10px;

        .spa-layer-content {
          background: $info-layer-background-color;
          max-height: 100%;
        }

        .spa-header {
          height: 34px;
          left: 11px;
          position: fixed;
          top: 21px;
          width: calc(-22px + 100%);
        }

        .spa-body {
          padding: 34px 5px 20px 20px;

          .content {
            max-height: 100%;
          }
        }

        &.spa-ui-animate-fadeInDown {
          animation-name: fadeInDownModal;
        }

        &.spa-ui-animate-fadeOutUp {
          animation-name: fadeOutUpModal;
        }

        &.spa-ui-animate-fadeInUp {
          animation-name: fadeInUpModal;
        }

        &.spa-ui-animate-fadeOutDown {
          animation-name: fadeOutDownModal;
        }
      }

      &-large {
        .spa-body {
          margin-right: $spacing-20;

          h3 {
            margin-right: 36px !important;
          }

          .content {
            margin-top: $spacing-40;
          }

          h3 {
            ~.content {
              margin-top: 0;
            }
          }
        }
      }
    }


    &-modal {
      .spa-ui-info {


        .spa-content {
          .spa-body {
            margin-right: 0 !important;
          }

          h2, h3 {
            color: $color-black !important;
            font-size: 16px !important;
            font-weight: $spa-font-weight-bold !important;
            line-height: 1.2 !important;
            margin: 0 0 16px 0 !important;
            padding: 0 !important;
            padding-right: 56px !important;
          }


          .content {
            p {
              color: $color-black !important;
              font-size: 16px !important;
              line-height: 1.3 !important;
            }

            a {
              color: $color-royal-one !important;
              font-size: 16px !important;
              line-height: 1.3 !important;

              &:hover {
                color: $color-royal-one !important;
              }
            }
          }
        }
        &-info-inline {
          position: relative !important;
          top: 0 !important;
          left: 0 !important;
          bottom: 0 !important;
          border: 1px solid #0395D3;
          box-shadow: none;
          border-radius: 4px;
          margin-top: 8px !important;

          .spa-card {
            box-shadow: none;
            flex-direction: row;
            background-color: #f2fafd;

            &__head-wrapper {
              min-height: 0 !important;
            }

            &__dismissible {
              margin: -9px 0 0 -12px !important;
            }

            &__body {
              padding-right: $spacing-20;
            }
          }
        }
      }
    }

  }

  .icon-link {
    border-radius: 50%;
    border: none;
    display: inline-block;
    font-style: normal;
    height: 44px;
    margin: auto;
    position: relative;
    vertical-align: middle;
    width: 44px;


    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;

    &:after {
      background-image: icon(info, (color: $color-royal-one));
      background-size: 100%;
      border-radius: 50%;
      bottom: 0;
      content: ' ';
      font-style: normal;
      height: 24px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      transition: box-shadow .5s ease-in-out;
      width: 24px;
    }


    &:hover,
    &:focus {
      border: 1px solid $color-royal-one;
      border-radius: $spacing-2;
    }
  }
}
